
/**
 * @name: coachManage
 * @author: Gzm
 * @date: 2023-05-30 10:27
 * @description：分销管理-教练管理
 * @update: 2023-05-30 10:27
 */
import {Component, Vue} from "vue-property-decorator";
import {ISystemAdmin} from "@/apis/core/types";
import {
  coachManageBatchAddApi,
  coachManageRemoveApi,
  coachManageStatusUpdateApi,
  queryCoachByPageApi,
  queryUserListApi
} from "@/apis/distributionManage/coachManage";
import {Message} from "element-ui";
import {ICoachByPageList} from "@/apis/distributionManage/coachManage/types";


@Component({})
export default class UserIndex extends Vue {

  queryForm: any = {
    page: 1,
    limit: 10
  }
  total: number = 0
  // 弹窗开关
  dialogVisible = false
  // titleList名称列表
  titleList = ['会员库', '教练库']
  // 会员库
  transferData = []
  // 教练库
  ids = []

  tableData: ISystemAdmin[] = []

  // 新增修改表单
  modelForm: ISystemAdmin = {
    status: 1
  }

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: any = {
    searchBox: true,
    menu: false,
    column: [
      {
        label: "教练ID",
        prop: "id",
        align: 'center',
        width: 200,
      },
      {
        label: "教练名称",
        prop: "userName",
        align: 'center',
      },
      {
        label: "教练",
        prop: "userName",
        search: true,
        placeholder: "教练名称",
        align: 'center',
        hide: true
      },
      {
        label: "手机号",
        prop: "phone",
        align: 'center',
      },
      {
        label: "教练课程",
        prop: "courseNumber",
        align: 'center',
      },
      {
        label: "教练客户",
        prop: "customNumber",
        align: 'center',
      },
      {
        label: "教练核销码",
        prop: "ticketCodeNumber",
        align: 'center',
      },
      {
        label: "提成余额",
        prop: "commissionBalance",
        align: 'center',
      },
      {
        label: "佣金",
        prop: "commission",
        align: 'center',
      },
      {
        label: "状态",
        prop: "abilityStatus",
        align: 'center',
        search: true,
        slot: true,
        value: 2,
        type: "switch",//状态:1=有效 2=无效
        dicData: [{label: '禁用', value: 2}, {label: '启用', value: 1}],
      },
      {
        label: "操作",
        prop: "operate",
        align: 'center',
        slot: true
      },
    ]
  }

  /**
   * 获取数据
   */
  getList() {
    queryCoachByPageApi(this.queryForm).then(e => {
      this.tableData = e.list;
      this.total = e.total
    })
  }

  /**
   * 修改状态提交
   */
  handleStatus(row: ICoachByPageList, val: number) {
    coachManageStatusUpdateApi({id: row.id, abilityStatus: val}).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList()
      }
    })
  }

  /**
   * 打开弹窗
   */
  async openAdd() {
    this.ids = []
    queryUserListApi(1).then(e => {
      this.transferData = e.map((item: any) => {
        return {
          key: item.id,
          label: `(${item.phone}) ${item.userName}`
        }
      })
      this.dialogVisible = true
    })
    // existingCoachApi(3).then(e => {
    //
    // })
  }

  /**
   * 删除
   * @param id
   */
  handleRemove(id: string) {
    this.$confirm('是否确认删除该教练?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      coachManageRemoveApi(id).then(e => {
        if (e) {
          Message.success("删除成功!")
          this.getList()
        }
      })
    }).catch(() => {
    });
  }

  /**
   * 新增教练
   */
  handleAffirm() {
    if (this.ids.length > 0) {
      let ids = this.ids.join(',')
      coachManageBatchAddApi(ids).then(e => {
        if (e) {
          Message.success("新增成功!")
          this.dialogVisible = false
          this.getList()
        }
      })
    } else {
      Message.error("请先选择教练")
    }
  }

  created() {
    this.getList();
  }
}
